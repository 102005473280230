import { fetchList } from "@/api/document";
export default {

    namespaced: true,

    state() {
        return {
            loading: false,
            list: [],
        }
    },



    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setList(state, payload) {
            state.list = payload
        }
    },

    actions: {

        getList({ commit },) {
            commit('setLoading', true)
            fetchList({
                pageNum: 1,
                pageSize: 100,
            })
                .then((res) => {
                    if (Array.isArray(res.list)) {
                        commit('setList', Object.freeze(res.list))
                    }
                })
                .finally(() => {
                    commit('setLoading', false)
                });
        },


    }
}