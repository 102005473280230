import { fetchListForSelector } from '@/api/employee'
export default {

    namespaced: true,

    state() {
        return {
            loading: false,
            list: [], // 员工列表
            recentSelectedList: [], // 最近选中
            sortType: 'sort', // 部门排序方式
            includes: 'qt', // 设置请求参数是否包括其他
        }
    },


    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setList(state, payload) {
            state.list = payload
        },
        setRecentSelectedList(state, payload) {
            window.localStorage.setItem('recent-selected-list', JSON.stringify(payload)) // 将最近选中的数据存储在 localStorage 中
            state.recentSelectedList = payload;
        },
        setSortType(state, payload) {
            window.localStorage.setItem('dept-sort-type', payload)
            state.sortType = payload;
        }
    },

    actions: {
        getList({ state, commit }) {
            return new Promise((resolve) => {
                commit('setLoading', true)
                fetchListForSelector({
                    includes: state.includes
                }).then(res => {
                    if (Array.isArray(res)) {
                        commit('setList', Object.freeze(res))
                        resolve();
                    }
                }).finally(() => {
                    commit('setLoading', false)
                })
            })


        }
    }

}