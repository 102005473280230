import { fetchList } from "@/api/setting/organization";

// 项目配置
export default {

    namespaced: true,

    state() {
        return {
            departmentList: [], // 部门列表
        }
    },

    getters: {

    },

    mutations: {
        setList(state, payload) {
            state.departmentList = payload;
        },
    },

    actions: {
        getDepartmentList({ commit }) {
            fetchList({
                status: 1,
            }).then((res) => {
                if (Array.isArray(res)) {
                    const list = Object.freeze(
                        res.sort((x, y) => {
                            if (x.sort < y.sort) {
                                return -1;
                            }
                            if (x.sort > y.sort) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                    );

                    commit('setList', list)
                }
            });

        }
    }

}