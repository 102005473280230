import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/org/list',
        params
    })
}

export function fetchDetail(params) {
    return request({
        url: '/user-service/org/query',
        params,
    })
}

export function fetchDetailTree(params) {
    return request({
        url: '/user-service/org/queryOrgDetail',
        params,
    })
}

export function add(data) {
    return request({
        url: '/user-service/org/add',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/user-service/org/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/user-service/org/del',
        method: 'post',
        data,
    })
}