import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/crmCompany/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/user-service/crmCompany/add',
        method: 'POST',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: `/user-service/crmCompany/query/${params.id}`,
    })
}

export function edit(data) {
    return request({
        url: '/user-service/crmCompany/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/user-service/crmCompany/del',
        method: 'POST',
        data
    })
}