import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { fetchDetail } from "@/api/setting/user";
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem('user'))
  if(user){
    fetchDetail({
      uuid: user.uuid,
    }).then((v) => {
      store.commit("auth/setUser", {
        ...user,
        employeeVo: v.employeeVo
      })
    });
  }
  

  if (to.path === '/register' || to.path === '/login' || to.path === '/internRegister') {
    next();
    return;
  }

  const token = window.localStorage.getItem("token");

  if (!token) {
    if (from.path === "/login") {
      next(false);
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
    return;
  }

  if (to.meta.isPublic) {
    next();
    return;
  }

  const menuList = window.localStorage.getItem("menuList");
  if (menuList) {
    const menus = JSON.parse(menuList);
    const res = menus.find((item) => item.path === to.path);

    console.log('before each check menu', res)

    if (res) {
      next();
    } else {
      next("/403");
    }
  } else {
    console.log("local storage don't has menu list");
    next("/403");
  }

});

export default router
