import { fetchDetail, fetchEmployeeList } from '@/api/project'
export default {

    namespaced: true,

    state() {
        return {
            project: {}, // 正在查看详情的项目
            step: 0, // 配置过程中到达了第几步

            selectEmployeeVisible: false, // 选择人员弹窗显隐
            employeeList: [], // 人员列表
            selectedList: [], // 选中的人员列表
            selectedKey: null, // 选中的变量名
            single: false, // 是否单选

            recentlySelected: [], // 最近选中的人员列表，功能待实现

            updateCollection: null, // 值变化，去更新收款明细列表


        }
    },

    getters: {

    },

    mutations: {
        setProject(state, payload) {
            window.localStorage.setItem("project", JSON.stringify(payload))
            state.project = payload;
        },
        setStep(state, payload) {
            state.step = payload
        },

        setSelectEmployeeVisible(state, payload) {
            state.selectEmployeeVisible = payload
        },
        setEmployeeList(state, payload) {
            state.employeeList = payload
        },
        setSelectedList(state, payload) {
            state.selectedList = payload
        },
        setSelectedKey(state, payload) {
            state.selectedKey = payload;
        },
        setSingle(state, payload) {
            state.single = payload
        },

        setUpdateCollection(state, payload) {
            state.updateCollection = payload;
        },

    },

    actions: {

        getProjectDetail({ commit, }, id) {
            console.log('id', id)
            fetchDetail({
                id,
            }).then(res => {
                commit('setProject', res)
            })
        },

        getEmployeeList({ commit }) {
            fetchEmployeeList().then(res => {
                if (Array.isArray(res)) {
                    // 过滤出非离职的员工 status === 1
                    commit('setEmployeeList', Object.freeze(res.filter(item => item.status === 1)))
                }
            })
        }
    }

}