// 按某个属性对 对象数组分类
// property 为需要分类的属性。返回一个对象
export function groupBy(objectArray = [], property = '') {
  if(!Array.isArray(objectArray)){
    return {} 
  }
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

// 还原分类后的数组
export function recoveryArr(obj) {
  if(!obj){
    return []
  }
  if(typeof obj !== 'object') {
    return [];
  }
  if(!Array.isArray(Object.values(obj))) {
    return [];
  }
  return Object.values(obj).reduce((a, b) => {
    return a.concat(b)
  })
}