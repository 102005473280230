export default {
    namespaced: true,

    state() {
        return {
            info: {}
        }
    },

    getters: {
        imagePath(state) {
            return `https://adm.ac.cn/static/img/weather/mango/${state.info.wea_img}.png`;
        },
    },

    mutations: {
        setInfo(state, payload) {
            state.loading = payload
        },
    },

    actions: {
        getWeather({ commit, }) {
            fetch("https://adm.ac.cn/Api/Weather/getForcast7?token=njszy")
                .then((res) => res.json())
                .then((res) => {
                    if (
                        res.info &&
                        Array.isArray(res.info.data) &&
                        res.info.data.length > 0
                    ) {
                        commit('setInfo', res.info.data[0])
                    }
                });
        },
    }

}