<template>
  <span :style="styleObject ? styleObject : styleObj">{{ amount }}</span>
</template>

<script>
export default {
  props: {
    money: {
      type: Number,
      default: 0,
    },
    precision: {
      type: Number,
      default: 6,
    },
    styleObject: {
      type: Object,
    }
  },
  computed: {
    amount() {
      if (typeof this.money === "number" && !isNaN(this.money)) {
        if (this.money === 0) {
          return "0";
        }
        const result = this.money / 10000;
        const arr = String(result).split(".");
        if (arr.length > 1) {
          if (arr[1].length <= 6) {
            return result;
          } else {
            return result.toFixed(6);
          }
        } else {
          // 整数
          return result;
        }
      } else {
        return "--";
      }
    },
    styleObj() {
      if (parseFloat(this.amount) < 0) {
        return {
          color: "#f00",
        };
      } else {
        return {
          color: "#1890ff",
        };
      }
    },
  },
};
</script>