export default {

    namespaced: true,

    state() {
        return {
            tabList: [], // tab 列表
        }
    },

    getters: {
        // 页面组件缓存列表，包含组件的 name 属性
        keepAliveList(state) {
            return state.tabList.filter(item => item.componentName).map(item => item.componentName)
        }
    },


    mutations: {
        setTabList(state, payload) {
            window.localStorage.setItem('tabList', JSON.stringify(payload)) // 持久化存储 tabs 列表
            state.tabList = payload
        },
        deleteTab(state, payload) {
            const index = state.tabList.findIndex(item => item.path === payload)
            if (index > -1) {
                state.tabList.splice(index, 1)
            }
        }
    },
}