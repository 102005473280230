// 面试
import request from '../request'
import download from '../download'

export function fetchList(data) {
    return request({
        url: '/office-service/career/resume/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/office-service/career/resume/add',
        data,
        method: "post",
    })
}

export function importFile(data) {
    return request({
        url: '/office-service/career/resume/import',
        data,
        method: "post",
    })
}

export function edit(data) {
    return request({
        url: '/office-service/career/resume/update',
        data,
        method: "post",
    })
}


export function remove(data) {
    return request({
        url: '/office-service/career/resume/del',
        data,
        method: "post",
    })
}

// 不录用
export function out(data) {
    return request({
        url: '/office-service/career/resume/rejected',
        data,
        method: "post",
    })
}

// 录用
export function hire(data) {
    return request({
        url: '/office-service/career/resume/hired',
        data,
        method: "post",
    })
}

export function previewOffer(data) {
    return download({
        url: '/office-service/career/resume/preview',
        data,
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    })
}

// 详情
export function fetchDetail(params) {
    return request({
        url: '/office-service/career/resume/query/' + params.id,
    })
}

// 面试单一和批量邀请
export function invite(data) {
    return request({
        url: '/office-service/career/interview/invitation',
        data,
        method: "post",
    })
}

// 同意和拒绝用户的面试申请调整
export function changeInterview(data) {
    return request({
        url: '/office-service/career/interview/change',
        data,
        method: "post",
    })
}

// 获取已邀请的面试官
export function fetchHRList(data) {
    return request({
        url: '/office-service/career/interviewer/query',
        data,
        method: "post",
    })
}
// 邀请面试官
export function inviteHR(data) {
    return request({
        url: '/office-service/career/interviewer/set',
        data,
        method: "post",
    })
}

// 修改邀约状态
export function changeStatus(data) {
    return request({
        url: '/office-service/career/resume/update/invitation',
        data,
        method: "post",
    })
}

export function downloadResume(data) {
    return download({
        url: '/office-service/career/resume/jianli/down',
        data,
        method: 'post',
    })
}

export function queryMsg(params) {
    return request({
        url: '/common-service/message/smsDetail',
        params,
        method: 'get',
    })
}