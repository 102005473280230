
import request from "@/api/request";

function fetchList() {
    return request({
        url: "/office-service/att/holiday/list",
    });
}

export default {

    namespaced: true,

    state() {
        return {
            holiday: [],
        }
    },

    mutations: {
        setHoliday(state, payload) {
            state.holiday = payload
        },

    },

    actions: {
        getHoliday({ commit }) {
            return new Promise((resolve, reject) => {
                fetchList().then(res => {
                    if (Array.isArray(res)) {
                        const list = res.map(item => {
                            return {
                                ...item,
                                data: typeof item.data === 'string' ? JSON.parse(item.data) : []
                            }
                        })
                        commit('setHoliday', Object.freeze(list))
                        resolve();
                    } else {
                        reject();
                    }
                }).catch(reject)
            })

        },
    }

}