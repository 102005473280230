// 项目详情页面
import { fetchDetail, fetchProgress, fetchProjectAnalysis } from '@/api/project'
export default {

    namespaced: true,

    state() {
        return {
            id: null, // 项目 ID
            project: {}, // 项目详情
            phaseList: [],
            phaseStatistic: []
        }
    },

    mutations: {
        setId(state, payload) {
            state.id = payload
        },
        setProject(state, payload) {
            state.project = payload;
        },
        setPhaseList(state, payload) {
            state.phaseList = payload
        },
        setPhaseStatistic(state, payload) {
            state.phaseStatistic = payload;
        }
    },

    actions: {
        fetchProject({ commit, state }) {
            commit('setProject', {})
            fetchDetail({
                id: state.id,
            }).then(res => {
                if (res) {
                    commit('setProject', res)
                }
            })
        },

        fetchPhaseList({ commit, state }) {
            commit('setPhaseList', [])

            fetchProgress({
                id: state.id,
            }).then(res => {
                if (Array.isArray(res)) {
                    commit('setPhaseList', res)
                }
            })
        },

        fetchPhaseStatistic({ commit, state }) {
            commit('setPhaseStatistic', [])
            fetchProjectAnalysis({
                id: state.id,
            }).then(res => {
                if (Array.isArray(res)) {
                    commit('setPhaseStatistic', res)
                }
            })
        },
    }

}