import store from '@/store'
import router from '@/router'

// 删除带缓存的页面，删除 tabList 并更新路由
export function close(path, newPath) {
    store.commit('tabs/deleteTab', path)
    if (newPath) {
        router.replace(newPath)
    } else {
        router.go(-1);
    }
}