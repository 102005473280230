import { fetchList } from "@/api/setting/area";
export default {
    namespaced: true,

    state() {
        return {
            rawAreaList: [],
            areaList: [],
        }
    },

    getters: {

    },

    mutations: {
        setRawAreaList(state, payload) {
            state.rawAreaList = payload
        },
        setAreaList(state, payload) {
            state.areaList = payload
        },
    },

    actions: {
        getAreaList({ commit }) {
            return new Promise((resolve) => {
                fetchList().then(res => {
                    if (Array.isArray(res)) {
                        commit('setRawAreaList', Object.freeze(res))
                        commit('setAreaList', Object.freeze(filterList(res, null)))
                        resolve();
                    }
                })
            })
        }
    }

}

function filterList(list, pid) {
    const tree = [];
    list.forEach((item) => {
        if (item.pid === pid) {
            let children = filterList(list, item.id);
            tree.push({
                ...item,
                key: item.id,
                children: children && children.length > 0 ? children : undefined,
            });
        }
    });
    return tree;
}