import request from '../request'
import download from '../download'

export function fetchList(data) {
    return request({
        url: '/project-service/project/list',
        method: 'post',
        data
    })
}

// 获取项目详情
export function fetchDetail(params) {
    return request({
        url: `/project-service/project/query/${params.id}`,
    })
}

export function remove(data) {
    return request({
        url: '/project-service/project/del',
        method: 'POST',
        data
    })
}

// 项目进度数据清理
export function clearData(data) {
    return request({
        url: `/project-service/project/clear/${data.id}`,
        method: 'POST',
    })
}

// 项目配置
export function config(data) {
    return request({
        url: '/project-service/project/configure',
        method: 'POST',
        data
    })
}

// 获取项目类型以及项目阶段列表
export function fetchTypeList(params) {
    return request({
        url: '/project-service/project/phasetype/list',
        params,
    })
}

// 收款
export function addMoney(data) {
    return request({
        url: '/project-service/payment/add',
        method: "POST",
        data,
    })
}
// 收款列表
export function fetchCollectionList(params) {
    return request({
        url: '/project-service/payment/list',
        params
    })
}

// 更新项目分包中的每项分包实际金额
export function updateSubpackageMoney(data) {
    return request({
        url: 'project-service/projectSub/update',
        data,
        method: 'POST'
    })
}

// 查询某一个项目的所有阶段的进度
export function fetchProgress({ id }) {
    return request({
        url: `/project-service/progress/list/${id}`,
    })
}

// 修改所有阶段的进度（后台发起审批）
export function updateProgress(data) {
    return request({
        url: '/project-service/progress/update',
        data,
        method: 'POST'
    })
}


// 修改（暂存）某一阶段的进度
export function saveProgress(data) {
    return request({
        url: '/project-service/progress/save',
        data,
        method: 'POST'
    })
}



// 进度100%后上传了一堆文件后保存
export function uploadFile(data) {
    return request({
        url: '/project-service/progress/file/upload',
        data,
        method: 'POST'
    })
}

// 删除上传的文件
export function deleteFile({ id }) {
    return request({
        url: '/project-service/progress/file/delete',
        data: { id },
        method: 'POST'
    })
}

// 查询某一个项目的某个阶段的分配模型
export function fetchModel({ id }) {
    return request({
        url: `/project-service/progress/query/${id}`,
    })
}

export function updateModel(data) {
    return request({
        url: '/project-service/progress/allocate/update',
        method: 'POST',
        data
    })
}


// 获取人员列表，创建项目时选择他们
export function fetchEmployeeList(params) {
    return request({
        url: '/user-service/employee/list',
        params
    })
}

// 查询每个人的分配情况
export function fetchAllocate(params) {
    return request({
        url: `/project-service/payment/allocatePayment/${params.id}`,
    })
}

// 克隆项目
export function clone(data) {
    return request({
        url: `/project-service/project/clone`,
        method: 'post',
        data,
    })
}

// 配置阶段查询项目的分配模型列表
export function fetchModelList(params) {
    return request({
        url: `/project-service/progress/queryProjectAllocate/${params.id}`
    })
}

// 分配金钱
export function allocate(data) {
    return request({
        url: `/project-service/progress/configure`,
        data,
        method: 'post'
    })
}

// 查询项目的数据权限
export function fetchPermission(params) {
    return request({
        url: `/common-service/userDataPermission/queryByValue`,
        params,
    })
}

// 获取项目下阶段下的专业
export function fetchMajor(params) {
    return request({
        url: `/project-service/project/phase/${params.id}`
    })
}

export function configPermission(data) {
    return request({
        url: `/common-service/userDataPermission/configUsers`,
        method: 'post',
        data
    })
}

// 查询项目的分配统计模型
export function fetchProjectAnalysis(params) {
    return request({
        url: `/project-service/project/allocate/stat/web/${params.id}`,
    })
}

// 配置项目前先校验项目
export function check(data) {
    return request({
        url: `/project-service/project/preConfigureValidate`,
        method: 'post',
        data,
    })
}


// 拆分项目
export function split(data) {
    return request({
        url: '/project-service/project/split',
        method: 'post',
        data
    })
}
export function splitSon(data) {
    return request({
        url: '/project-service/project/splitSon',
        method: 'post',
        data
    })
}

// 项目详情页面调整申请
export function apply(data) {
    return request({
        url: '/project-service/adjust/add',
        method: 'post',
        data
    })
}

// 查询调整申请审批的详情
export function fetchUpdateApplyDetail(params) {
    return request({
        url: `/project-service/adjust/query/${params.id}`,
    })
}

// 导出
export function fetchDownloadFile(data) {
    return download({
        url: '/project-service/project/exportPayment',
        data,
    })
}

export function downloadProgress(data) {
    console.log('params', data)
    return download({
        url: '/project-service/project/login/exportAppointHaveProgress',
        data
    })
}

// 产值分配时，选择专业负责人
export function setMajorUser(data) {
    return request({
        url: '/project-service/progress/configure/save',
        method: 'post',
        data,
    })

}

// 专业负责人修改各个阶段的人员产值
export function setMoneyByMajorUser(data) {
    return request({
        url: '/project-service/progress/configure/allocate/save',
        method: 'post',
        data,
    })
}

// 专业负责人查询某个项目下自己需要分配的情况
export function getMoneyByMajorUser({ id }) {
    return request({
        url: '/project-service/progress/queryProjectAllocate/allocate/' + id,
    })
}