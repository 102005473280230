<template>
  <div>
    <span v-if="iconType === 'badge'">
      <a-badge
        v-if="badgeType === 'status'"
        :status="dict.icon ? dict.icon : 'default'"
        :text="dict.name"
      />
      <a-badge
        v-else
        :color="dict.icon ? dict.icon : '#999999'"
        :text="dict.name"
      />
    </span>
    <a-tag
      v-else-if="iconType === 'tag'"
      :color="dict.icon ? dict.icon : 'blue'"
      style="margin-right: 0"
    >
      {{ dict.name }}
    </a-tag>
    <span v-else-if="iconType === 'text'" :style="{ color: dict.icon }">{{
      dict.name
    }}</span>
    <span v-else>{{ dict.name }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dictType: {
      type: String,
      default: "",
    },
    dictValue: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "",
    },
    badgeType: {
      type: String,
      default: "color",
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    dict() {
      const list = this.findDataDict(this.dictType);
      if (/,/.test(this.dictValue)) {
        const arr = this.dictValue.split(",");
        const name = arr
          .map((element) => {
            const obj = list.find((item) => item.value === element);
            return obj ? obj.name : "";
          })
          .join("，");
        return {
          name,
        };
      }
      const obj = list.find((item) => item.value === this.dictValue);
      if (obj) {
        return obj;
      } else {
        return {};
      }
    },
  },
};
</script>