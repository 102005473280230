export default {

    namespaced: true,

    state() {
        return {
            user: {}, // 忘记密码时用户通过验证码获取到的用户信息
        }
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
    },

}
