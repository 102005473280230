// 项目配置
export default {

    namespaced: true,

    state() {
        return {
            project: {}, // 正在配置的项目
            step: null, // 配置过程中到达了第几步
        }
    },

    getters: {

    },

    mutations: {
        setProject(state, payload) {

            state.project = payload;
        },
        setStep(state, payload) {
            state.step = payload
        },

    },

    actions: {

    }

}