import request from "@/api/request";

function fetchIndexList() {
  return request({
    url: "/office-service/study/course/index/list",
  });
}


function buildTree(list, pid) {
    const tree = [];
    list.forEach((item) => {
        if (item.pid === pid) {

          const params = {
                ...item,
            }

            const children = buildTree(list, item.id);

            children.sort((x,y) => y.sort - x.sort)

            if (children.length > 0) {
                params.children = children;
            }

            tree.push(params);
        }
    });

    return tree;
}

export default {

    namespaced: true,

    state() {
        return {
            directoryLoading: false,
            rawList: [],
            tree: [],
            useableTree: []
        }
    },

    mutations: {
        setDirectoryLoading(state, payload) {
            state.directoryLoading = payload;
        },
        setRawList(state, payload) {
            state.rawList = payload
        },
        setTree(state, payload) {
            state.tree = payload;
        },
            setUseableTree(state, payload) {
            state.useableTree = payload;
        },
    },

    actions: {
        getDirectory({ commit,  }) {
            commit('setDirectoryLoading', true)
            fetchIndexList().then(res => {
                    const rawList = Array.isArray(res) ? res: [];
                    const tree = buildTree(rawList, null)
                    const useableTree = buildTree(rawList.filter(item => item.status === 1 ), null)
                    commit('setRawList', rawList)
                    commit('setTree', tree)
                    commit('setUseableTree', useableTree)
              }).finally(() => {
                commit('setDirectoryLoading', false)
            })
        },
    }

}


