// 面试详情页面
import { fetchDetail } from "@/api/recruit";
import { fetchList } from "@/api/setting/log";

export default {

    namespaced: true,

    state() {
        return {
            id: null, // 面试者 ID
            detail: {}, // 详情

            workVisible: false, // 添加工作经历弹窗
            schoolVisible: false,
            familyVisible: false,
            campVisible: false,
            internshipVisible: false,

            activeResumeType: 'base-info', // 显示的简历分类

            log: [],
            logTotal: 0,
        }
    },

    getters: {
        // 当前面试者面试邀请记录
        interviewList(state) {
            return Array.isArray(state.detail.interviewList) ? state.detail.interviewList.reverse() : []
        },
        // 参与该面试者的面试官列表
        hrList(state) {

            if (
                Array.isArray(state.detail.interviewerList)
            ) {
                const phaseList = Array.from(new Set(state.detail.interviewerList.map(item => item.phase)))
                return phaseList.map(phase => {
                    return {
                        phase,
                        list: state.detail.interviewerList.filter(element => element.phase === phase)
                    }
                })
            } else {
                return []
            }
        },

        workList(state) {
            return Array.isArray(state.detail.hisWorkList)
                ? state.detail.hisWorkList
                : [];
        },
    },

    mutations: {
        setId(state, payload) {
            state.id = payload
        },
        setDetail(state, payload) {
            state.detail = payload;
        },
        setWorkVisible(state, payload) {
            state.workVisible = payload
        },
        setSchoolVisible(state, payload) {
            state.schoolVisible = payload
        },
        setFamilyVisible(state, payload) {
            state.familyVisible = payload
        },
        setCampVisible(state, payload) {
            state.campVisible = payload
        },
        setInternshipVisible(state, payload) {
            state.internshipVisible = payload
        },


        setActiveResumeType(state, payload) {
            state.activeResumeType = payload
        },
        setLog(state, payload) {
            state.log = payload
        },
        setLogTotal(state, payload) {
            state.logTotal = payload
        },

    },

    actions: {
        getDetail({ commit, state }) {
            fetchDetail({
                id: state.id,
            }).then(res => {
                if (res) {
                    commit('setDetail', res)
                }
            })
        },
        // 获取面试记录
        getLog({ commit, state }) {
            fetchList({
                pageNum: 1,
                pageSize: 100,
                conds: [
                    {
                        condName: "businessKey",
                        condValue: [
                            `CareerResume:${state.id}`,
                        ],
                    },
                ],
            }).then((res) => {
                if (Array.isArray(res.list)) {
                    commit('setLog', res.list)
                    commit('setLogTotal', res.totalSize)
                }
            });


        }
    }

}