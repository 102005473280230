export default {

    namespaced: true,

    state() {
        return {
            list: [],
        }
    },

    getters: {

    },

    mutations: {
        setList(state, payload) {
            state.list = payload;
        },
    },
}