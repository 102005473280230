import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: '/finance-service/invoice/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/finance-service/invoice/add',
        method: 'POST',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: `/finance-service/invoice/query/${params.id}`,
    })
}

export function edit(data) {
    return request({
        url: '/finance-service/invoice/update',
        method: 'POST',
        data
    })
}

export function remove(data) {
    return request({
        url: '/finance-service/invoice/updateStatus',
        method: 'POST',
        data
    })
}

// 红冲发票
export function redoffset(data) {
    return request({
        url: '/finance-service/invoice/redoffset',
        method: 'POST',
        data
    })
}

// 上传发票 Excel 文件
export function upload(data) {
    return request({
        url: '/finance-service/invoice/import',
        data,
        method: 'POST'
    })
}

export function exportFile(data) {
    return download({
        url: '/finance-service/invoice/export',
        data,
    })
}

// 查询某个项目的开票金额
export function fetchProjectInvoiceAmount(params) {
    return request({
        url: `/finance-service/invoice/query/contract/${params.id}`
    })
}

// 开票退票申请
export function apply(data) {
    return request({
        url: '/finance-service/invoice/apply',
        method: 'post',
        data,
    })
}


export function fetchContractList(data) {
    return request({
        url: '/project-service/contract/invoice/list',
        data,
        method: 'post'
    })
}