import { fetchList } from "@/api/setting/organization";
export default {
    namespaced: true,

    state() {
        return {
            rawList: [], // 原始列表
            organizationList: [], // 组织列表（设计院下的一级部门）
        }
    },

    getters: {
        // 生产部门
        productionDepartmentList(state) {
            return state.organizationList.filter(item => item.type !== 'function')
        }
    },

    mutations: {
        setRawList(state, payload) {
            state.rawList = payload
        },
        setOrganizationList(state, payload) {
            state.organizationList = payload
        },
    },

    actions: {
        getOrganizationList({ commit }) {

            return new Promise((resolve) => {
                fetchList({
                    status: 1,
                }).then((res) => {
                    if (Array.isArray(res)) {

                        const list = res.sort((x, y) => {
                            if (x.sort < y.sort) {
                                return -1;
                            }
                            if (x.sort > y.sort) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })

                        commit('setRawList', Object.freeze(res));
                        commit("setOrganizationList", Object.freeze(list.filter(item => item.type)))
                        resolve();
                    }
                });
            })



        },
    }

}