import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/message/list',
        params
    })
}

export function read(params) {
    return request({
        url: `/common-service/message/read/${params.id}`,
    })
}

export function readAll(data) {
    return request({
        url: '/common-service/message/read',
        method: 'post',
        data,
    })
}

export function sendMessage(data) {
    return request({
        url: '/common-service/message/add',
        method: 'post',
        data
    })
}