// 金额分配
export default {

    namespaced: true,

    state() {
        return {
            list: [], // 项目的阶段列表：包括每个阶段的分配模型（小阶段、子项、专业、其他），其中模型里面有占比、金额和人员列表
            project: {}, // 项目信息
        }
    },

    getters: {

    },

    mutations: {


        setList(state, payload) {
            state.list = payload;
        },

        setProject(state, payload) {
            state.project = payload
        }
    },

}