
import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/article/list',
        params
    })
}

export function fetchDetail(params) {
    return request({
        url: `/common-service/article/query/${params.id}`,
    })
}

export function add(data) {
    return request({
        url: '/common-service/article/add',
        method: 'POST',
        data
    })
}


export function edit(data) {
    return request({
        url: '/common-service/article/update',
        method: 'POST',
        data
    })
}


export function remove(data) {
    return request({
        url: '/common-service/article/del',
        method: 'POST',
        data
    })
}
