import request from '../request'

// 查询当前登录用户的审批任务列表
export function fetchTask(data) {
    return request({
        url: '/activiti-service/servicetask/user/tasks',
        data,
        method: 'POST'
    })
}

// 查询审批任务详情的历史纪录和当前任务的信息
export function fetchTaskDetail(data) {
    return request({
        url: '/activiti-service/servicetask/task/index',
        data,
        method: 'POST'
    })
}

// 替换url
export function replaceUrl(data) {
    return request({
        url: '/common-service/file/transUrl/String/front',
        data,
        method: 'POST'
    })
}

// 完成审批任务
export function done(data) {
    return request({
        url: '/activiti-service/servicetask/task/complete',
        data,
        method: 'POST'
    })
}

// 完成待阅的任务
export function read(data) {
    return request({
        url: '/activiti-service/activiti/task/notify/complete',
        data,
        method: 'post'
    })
}

// 暂存任务
export function storage(data) {
    return request({
        url: '/activiti-service/servicetask/task/tempsave',
        data,
        method: 'post'
    })
}

// // 已办审批任务取回功能
export function takeBackTask(data) {
    return request({
        url: '/activiti-service/servicetask/withdraw',
        data,
        method: "POST"
    })
}

export function fetchLog(params) {
    return request({
        url: '/activiti-service/activiti/history/model?instanceId=' + params.instanceId
    })
}
// 加签查询
export function fetchSign(data) {
    return request({
        url: '/activiti-service/servicetask/sign/get',
         data,
        method: 'post',
    })
}

// 加签暂存
export function signStorage(data) {
    return request({
        url: '/activiti-service/servicetask/sign/add',
        data,
        method: 'post'
    })
}

// 加签提交
export function signSubmit(data) {
    return request({
        url: '/activiti-service/servicetask/sign/commit',
        data,
        method: 'post'
    })
}