import request from '../request'

// 看板

// 全院合同
export function fetchByYear(params) {
    return request({
        url: `/project-service/databoard/main/${params.year}`,
    })
}

// 全院营收
export function fetchIncome(params) {
    return request({
        url: `/project-service/databoard/business/${params.year}`,
    })
}

// 区域经营
export function fetchArea(params) {
    return request({
        url: `/project-service/databoard/area/${params.year}`
    })
}

// 部门经营
export function fetchDepartment(params) {
    return request({
        url: `/project-service/databoard/dept/${params.deptId}/${params.year}`
    })
}
export function fetchDepartmentOldData(params) {
    return request({
        url: `/project-service/databoard/dept2/${params.year}`
    })
}

// 专业板块
export function fetchMajor(params) {
    return request({
        url: `/project-service/databoard/major/${params.year}`
    })
}

// 回款
export function fetchReceivedPayment(params) {
    return request({
        url: `/project-service/databoard/receivable/${params.year}`
    })
}