import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import auth from './modules/auth'
import setting from './modules/setting'
import project from './modules/project'
import tabs from './modules/tabs'
import message from './modules/message'
import projectConfig from './modules/project-config'
import allocate from './modules/allocate'
import allocate2021 from './modules/allocate2021'
import allocate2022 from './modules/allocate2022'
import allocate2023 from './modules/allocate2023'
import majorAllocate from './modules/major-allocate'
import adjustAllocate from './modules/adjust-allocate'
import adjustMajorAllocate from './modules/adjust-major-allocate'
import projectDetail from './modules/project-detail'
import projectSelector from './modules/project-selector'
import contractSelector from './modules/contract-selector'

import companySelector from './modules/company-selector'

import invoiceSelector from './modules/invoice-selector'

import socket from './modules/socket'

import approval from './modules/approval'
import approvalDetail from './modules/approval-detail'

import department from './modules/department'

import organization from './modules/setting/organization'
import area from './modules/setting/area'

import employeeSelector from './modules/selector/employee-selector'

import weather from './modules/weather'
import document from './modules/document'

import interviewDetail from './modules/interview-detail'

import humanResources from './modules/human-resources'

import draft from './modules/draft'

import epc from './modules/epc'

import holiday from './modules/holiday'

import study from './modules/study'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    login,
    auth,
    setting,
    project,
    tabs,
    message,
    projectConfig, // 项目配置
    allocate, // 给阶段分配金额
    allocate2021,
    allocate2022,
    allocate2023,
    majorAllocate,
    adjustAllocate, // 调整阶段的产值分配
    adjustMajorAllocate, // 调整阶段的 专业 的产值分配

    projectDetail, // 项目详情页面
    socket,

    approval, // 审批
    approvalDetail, // 审批详情页面

    projectSelector, // 项目选择器
    companySelector, // 公司选择器
    contractSelector, // 合同选择器

    invoiceSelector, // 发票选择器

    department,

    organization, // 组织
    area, // 区域

    employeeSelector, // 员工选择器

    weather, // 天气
    document, // 文章

    interviewDetail, // 面试详情页面

    humanResources, // 人力资源-组织架构管理

    draft, // 草稿箱

    epc,

    holiday, // 国家法定节假日

    study, // 在线学习


  }
})
