let baseURL;

if (process.env.NODE_ENV === 'production') {
    baseURL = '/api'
}

export default function ({ url, method = 'get', data, headers = {} }) {
    return new Promise((resolve, reject) => {

        let body;
        let params = '?'
        if (method === 'post') {
            if (data) {
                body = JSON.stringify(data)
            }

            return fetch(`${baseURL ? baseURL + url : url}`, {
                    method,
                    headers: {
                        token: window.localStorage.getItem('token'),
                        ...headers,
                        'content-type': 'application/json'
                    },
                    body,
                }).then(res => {
                    resolve(res.blob())
                }).catch(reject)
        } else {
            if (data) {
                const keys = Object.keys(data)

                keys.forEach((key, index) => {
                    if (keys.length === index + 1) {
                        params += `${key}=${data[key]}`
                    } else {
                        params += `${key}=${data[key]}&`
                    }
                })
                if (params.length > 1) {
                    url += params;
                }
            }

            return fetch(`${baseURL ? baseURL + url : url}`, {
                method,
                headers: {
                    token: window.localStorage.getItem('token'),
                    ...headers
                },
                body,
            }).then(res => {
                resolve(res.blob())
            }).catch(reject)
        }

    })

}