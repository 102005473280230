import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/employee/list',
        params
    })
}

export function fetchListForSelector(params) {
    return request({
        url: '/user-service/employee/selection',
        params
    })
}

export function add(data) {
    return request({
        url: '/user-service/employee/add',
        method: 'post',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: `/user-service/employee/queryById/${params.id}`
    })
}

export function edit(data) {
    return request({
        url: '/user-service/employee/update',
        method: 'post',
        data,
    })
}


export function remove(data) {
    return request({
        url: '/user-service/employee/delete',
        method: 'post',
        data
    })
}
