<template>
  <a-button
    v-if="routeObj"
    :type="type"
    :size="size"
    @click="onClick"
    :icon="icon"
    :disabled="disabled"
  >
    <router-link
      v-if="routeType === 'link'"
      class="link"
      :to="{ path: fullPath, query: params }"
      :style="styleObj"
    >
      <span>
        <slot></slot>
      </span>
    </router-link>
    <span v-else class="link" :style="styleObj">
      <slot></slot>
    </span>
  </a-button>
  <span v-else></span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    path: {
      type: String,
      default: "",
    },
    routeType: {
      type: String,
      default: "link", // 'method'
    },
    type: {
      type: String,
      default: "link",
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
      default: "small",
    },
    danger: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullPath() {
      return this.$route.path + "/" + this.path;
    },
    ...mapGetters("auth", ["getCurrentRoute"]),
    routeObj() {
      return this.getCurrentRoute(this.fullPath);
    },
    styleObj() {
      const obj = {};
      if (this.danger) {
        obj.color = "#f00";
      }
      if (this.disabled) {
        obj.color = "#d9d9d9";
      }

      return obj;
    },
  },

  methods: {
    onClick() {
      if (this.routeType === "method") {
        this.$emit("clickButton");
      }
    },
  },
};
</script>


<style lang="less" scoped>
.link {
  display: flex;
  align-items: center;
}
</style>