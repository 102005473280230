import { fetchList } from "@/api/setting/organization";

// 项目配置
export default {

    namespaced: true,

    state() {
        return {
            list: [], // 部门树
        }
    },

    mutations: {
        setList(state, payload) {
            state.list = payload;
        },
    },

    actions: {
        getList({ commit }, params) {
            fetchList({
                isHierarchical: true, // 显示层级关系
                ...params,
            }).then((res) => {
                if (Array.isArray(res)) {
                    orgSort(res)
                    commit('setList', Object.freeze(res))
                }
            });

        }
    }

}

function orgSort(list) {
    list.forEach(item => {
        if (Array.isArray(item.orgs)) {
            orgSort(item.orgs)
        }
    });
    list.sort((x, y) => x.sort - y.sort)
}