import request from '../request'

export function fetchList() {
    return request({
        url: '/common-service/draftBox/list'
    })
}

export function update(data) {
    return request({
        url: '/common-service/draftBox/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/common-service/draftBox/del',
        method: 'post',
        data,
    })
}