import { fetchList, read } from '@/api/message'
export default {

    namespaced: true,

    state() {
        return {
            hasReadList: [], // 已读消息列表
            hasReadCurrent: 1,
            hasReadTotal: 0,

            notReadList: [], // 未读消息列表
            notReadCurrent: 1,
            notReadTotal: 0,
        }
    },

    mutations: {
        setHasReadList(state, payload) {
            state.hasReadList = payload
        },
        setHasReadCurrent(state, payload) {
            state.hasReadCurrent = payload
        },
        setHasReadTotal(state, payload) {
            state.hasReadTotal = payload
        },
        setNotReadList(state, payload) {
            state.notReadList = payload
        },
        setNotReadCurrent(state, payload) {
            state.notReadCurrent = payload
        },
        setNotReadTotal(state, payload) {
            state.notReadTotal = payload
        },
    },

    actions: {
        getMessageList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                fetchList(payload).then(res => {
                    if (Array.isArray(res.list)) {
                        if (payload.isRead === 0) {
                            // 未读
                            commit('setNotReadList', Object.freeze(res.list))
                            commit('setNotReadTotal', Object.freeze(res.totalSize))
                            resolve()
                        }
                        else if (payload.isRead === 1) {
                            // 已读
                            commit('setHasReadList', Object.freeze(res.list))
                            commit('setHasReadTotal', Object.freeze(res.totalSize))
                            resolve();
                        }
                    } else {
                        reject();
                    }
                }).catch(reject)
            })

        },
        readMessage(context, payload) {
            return new Promise((resolve, reject) => {
                read(payload).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
            })
        }
    }

}