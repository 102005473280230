import request from '../request'

export function fetchList(data) {
    return request({
        url: '/log-service/logs/query',
        method: 'POST',
        data
    })
}

export function fetchModuleList(data) {
    return request({
        url: '/log-service/logs/stat',
        method: "post",
        data
    })
}

export function fetchSystemList(data) {
    return request({
        url: '/log-service/logs/logback/query',
        method: 'POST',
        data
    })
}