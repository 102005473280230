export default {

    namespaced: true,

    state() {
        return {
            flag: '', // 当 WebSocket 接收到更新事件时，修改此变量
        }
    },

    mutations: {
        setFlag(state, payload) {
            state.flag = payload
        },
    },

    actions: {

    }

}