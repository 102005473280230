import { fetchList } from '@/api/setting/company'
export default {

    namespaced: true,

    state() {
        return {
            loading: false,
            current: 1,
            pageSize: 10,
            list: [],
            total: 0,
            searchValue: '',
        }
    },

    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setCurrent(state, payload) {
            state.current = payload;
        },
        setPageSize(state, payload) {
            state.pageSize = payload;
        },
        setList(state, payload) {
            state.list = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setSearchValue(state, payload) {
            state.searchValue = payload;
        }
    },

    actions: {
        getList({ commit, state }) {
            commit('setLoading', true)

            const params = {
                pageNum: state.current,
                pageSize: state.pageSize,
            }

            if (state.searchValue) {
                params.name = state.searchValue;
            }
            fetchList(params).then(res => {
                if (res && Array.isArray(res.list) && res.totalSize) {
                    commit('setList', Object.freeze(res.list))
                    commit('setTotal', res.totalSize)

                }
            }).finally(() => {
                commit('setLoading', false)
            })
        },
    }

}