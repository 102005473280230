import {
  fetchTaskDetail,
  done,
  storage,
  fetchLog,
  fetchSign,
  signStorage,
  signSubmit,
} from "@/api/approval";

export default {
  namespaced: true,

  state () {
    return {
      taskId: null, // 审批任务 id
      instanceId: null, // 审批流 id
      loading: false, // 加载状态
      detail: {}, // 审批任务详情
      history: [], // 历史记录

      oldDelayedDate: null, // 旧的延期字段
      log: {}, // 审批日志

      // 加签
      assignees: [], //加签人
      signType: "", //加签类型
      onset: false, //是否显示选择按钮（设置是否立即结束当前任务，不结束则可查看会签的其他人的审核记录）
    };
  },

  getters: {
    // 是否是合同审批
    isContract (state) {
      return state.detail.processDefinitionKey === "con_configure";
    },
    // 是否是项目阶段进度审批
    isProgress (state) {
      return state.detail.processDefinitionKey === "prj_phase_progress";
    },
    // 是否是项目阶段产值分配审批
    isAllocate (state) {
      return state.detail.processDefinitionKey === "prj_configure";
    },
    // 任务类型为：合同或项目申请调整
    isAdjust (state) {
      return state.detail.processDefinitionKey === "prj_configure_adjust";
    },

    disabled (state) {
      // 不是待审核就全禁用
      // 不是待重新提交就禁用
      // 待阅已阅的任务没有操作权限
      return (
        (state.detail.status !== "ASSIGNED" &&
          state.detail.status !== "RESUBMITED") ||
        state.detail.taskDefinitionKey === "notify_task"
      ); // 有值就禁用
    },

    // 打分是否渲染，只有阶段进度审批时，并且是区域指挥完成这项审批的时候
    scoreVisible (state) {
      return (
        state.detail.taskDefinitionKey ===
        "prj_progress_y_project_area_director"
      );
    },
    score (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.phaseProgressData
      ) {
        return state.detail.extra.phaseProgressData.score;
      } else {
        return 0;
      }
    },

    // 选择产值分配计算公式
    formulaVisible (state) {
      return state.detail.taskDefinitionKey === "prj_progress_formula";
    },
    // 公式
    formula (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.phaseProgressData
      ) {
        return state.detail.extra.phaseProgressData.formula;
      } else {
        return null;
      }
    },
    // 系数
    factor (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.phaseProgressData
      ) {
        return state.detail.extra.phaseProgressData.factor || 0.12;
      } else {
        return 0.12;
      }
    },

    // 是否是督办审批任务
    isSupervise (state) {
      return state.detail.processDefinitionKey === "ofc_supervise";
    },
    // 是否是督办任务的完成者（不是发起者）
    isSuperviseWorker (state) {
      return state.detail.taskDefinitionKey === "ofc_supervise_worker";
    },

    // 是否需要财务审批
    needFinancialApproval (state) {
      return state.detail.taskDefinitionKey === "prj_allocate_first_review" && state.detail.processDefinitionKey === 'con_amount_adjust'
    },
    isFinancialApproval (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.contractChangeAmountData
      ) {
        return state.detail.extra.contractChangeAmountData.isFinancialApproval;
      } else {
        return null;
      }
    },

    // 完成情况说明
    executeRemarks (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.superviseTaskData
      ) {
        return state.detail.extra.superviseTaskData.executeRemarks;
      } else {
        return null;
      }
    },
    // 完成情况附件
    executeAttachments (state) {
      if (
        state.detail &&
        state.detail.extra &&
        state.detail.extra.superviseTaskData &&
        Array.isArray(state.detail.extra.superviseTaskData.executeAttachments)
      ) {
        return state.detail.extra.superviseTaskData.executeAttachments;
      } else {
        return [];
      }
    },
    // 是否延期
    isDelayed (state) {
      if (state.detail.extra && state.detail.extra.superviseTaskData) {
        return state.detail.extra.superviseTaskData.isDelayed;
      }
      return 0;
    },

    // 完成情况说明
    delayedDate (state) {
      if (
        state.detail.extra &&
        state.detail.extra.superviseTaskData &&
        state.detail.extra.superviseTaskData.delayedDate
      ) {
        return state.detail.extra.superviseTaskData.delayedDate;
      } else {
        return null;
      }
    },

    delayRemarks (state) {
      if (
        state.detail.extra &&
        state.detail.extra.superviseTaskData &&
        state.detail.extra.superviseTaskData.delayRemarks
      ) {
        return state.detail.extra.superviseTaskData.delayRemarks;
      } else {
        return null;
      }
    },

    // 是否是开票、退票申请
    isInvoice (state) {
      return state.detail.processDefinitionKey === "fin_invoice";
    },

    // 开票申请中的填写票的人
    isFinanceDrawer (state) {
      return state.detail.taskDefinitionKey === "inv_finance_drawer";
    },

    // 这次申请的类型：开票、退票、换票
    applyType (state) {
      return state.detail.extra?.applyType;
    },

    // 用户填完的销方
    salesUnitName (state) {
      return state.detail.extra?.invoiceInfos?.salesUnitName;
    },

    // 开票人
    drawer (state) {
      return state.detail.extra?.invoiceInfos?.drawer;
    },
    // 开票日期
    drawDate (state) {
      return state.detail.extra?.invoiceInfos?.drawDate;
    },

    // 用户已经填完的发票列表
    invoiceList (state) {
      return state.detail.extra?.invoiceInfos?.invoiceList ?? [];
    },

    // 开票退票换票申请中需要填写送达信息的人
    isPostMan (state) {
      return (
        state.detail.taskDefinitionKey === "inv_branch_interface_1" ||
        state.detail.taskDefinitionKey === "inv_applicant"
      );
    },
    sendInfo (state) {
      if (state.detail.extra && state.detail.extra.sendInfo) {
        return state.detail.extra.sendInfo;
      } else {
        return {};
      }
    },
    // 送达附件
    sendAttachment (state) {
      if (state.detail.extra && state.detail.extra.sendInfo) {
        return state.detail.extra.sendInfo.attachment;
      }
    },
    // 开票退票换票申请中需要确认送达信息的人
    isConfirmer (state) {
      return (
        state.detail.taskDefinitionKey === "inv_product_master_1" ||
        state.detail.taskDefinitionKey === "inv_applicant_leader_1"
      );
    },
    // 是否确认送达
    isSent (state) {
      if (state.detail.extra && state.detail.extra.sendInfo) {
        return state.detail.extra.sendInfo.isSent;
      }
    },

    // 是否是档案出借审批
    isArchiveBorrow (state) {
      return state.detail.processDefinitionKey === "arc_borrow";
    },

    // 是否是档案移交审批
    isArchiveTransfer (state) {
      return state.detail.processDefinitionKey === "arc_transfer";
    },

    isMaterial (state) {
      return state.detail.processDefinitionKey === "ofc_inv_apply";
    },
    // 是否是合同登记审批
    isContractRegister (state) {
      return state.detail.processDefinitionKey === "con_register";
    },
    // 是否是合同调整
    isContractAdjust (state) {
      return state.detail.processDefinitionKey === "con_amount_adjust";
    },
    // 是否是分包登记审批
    isSubpackageRegister (state) {
      return state.detail.processDefinitionKey === "subcon_register";
    },
    // 是否是框架类合同登记审批
    isFrameworkContractRegister (state) {
      return state.detail.processDefinitionKey === "con_kj_register";
    },

    // 是否是总承包项目组织机构建立
    isEpcOrganize (state) {
      return state.detail.processDefinitionKey === "epc_project";
    },
    // 是否是投标控制预算审核意见表
    isBudgetComment (state) {
      return state.detail.processDefinitionKey === "epc_budget_review";
    },
    // 是否投标控制预算审批表
    isEpcBidbudget (state) {
      return state.detail.processDefinitionKey === "epc_budget";
    },

    // 是否是管理目标责任书
    isManageObject (state) {
      return state.detail.processDefinitionKey === "epc_manage_objective";
    },
    // 是否是管理计划
    isManagePlan (state) {
      return state.detail.processDefinitionKey === "epc_manage_plan";
    },
    // 是否是管理计划变更
    isManagePlanChange (state) {
      return state.detail.processDefinitionKey === "epc_manage_plan_update";
    },

    // 是否是施工进度计划
    isConstructionSchedule (state) {
      return state.detail.processDefinitionKey === "epc_construct_progress";
    },
    // 是否是施工进度计划
    isConstructionScheduleChange (state) {
      return (
        state.detail.processDefinitionKey === "epc_construct_progress_update"
      );
    },
    // 是否是实施控制预算
    isConstructionBudget (state) {
      return state.detail.processDefinitionKey === "epc_implementation_budget";
    },
    // 是否是策划 实施控制预算审核
    isPlanBudgetComment (state) {
      return (
        state.detail.processDefinitionKey === "epc_implementation_budget_review"
      );
    },
    // 是否是设计成果确认
    isResultConfirm (state) {
      return state.detail.processDefinitionKey === "epc_result_confirm";
    },
    // 是否是设计变更审批-符合审计原则
    isChangeReview (state) {
      return state.detail.processDefinitionKey === "epc_change_review";
    },
    // 是否是设计变更审批-不符合审计原则
    isChangeApproval (state) {
      return state.detail.processDefinitionKey === "epc_change_approval";
    },
    // 是否是施工组织设计
    isOrganizeDesign (state) {
      return state.detail.processDefinitionKey === "epc_construction_build";
    },
    // 是否是施工组织设计
    isQualityInspection (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_construction_quality_inspection"
      );
    },
    isSafeInspection (state) {
      return state.detail.processDefinitionKey === "epc_construction_security";
    },
    isDataInspection (state) {
      return (
        state.detail.processDefinitionKey === "epc_construction_document_review"
      );
    },
    isClaim (state) {
      return state.detail.processDefinitionKey === "epc_construction_claim";
    },
    isCounterClaim (state) {
      return (
        state.detail.processDefinitionKey === "epc_construction_counter_claim"
      );
    },
    isVisa (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_construction_subpackaged_visa"
      );
    },
    isViolation (state) {
      return (
        state.detail.processDefinitionKey === "epc_construction_security_rule"
      );
    },
    isAccident (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_construction_security_accident"
      );
    },
    isTrailOperationPlan (state) {
      return (
        state.detail.processDefinitionKey === "epc_closing_trial_operation_plan"
      );
    },
    isPerformAssess (state) {
      return (
        state.detail.processDefinitionKey === "epc_closing_perform_assess_app"
      );
    },

    isMasterPlan (state) {
      return state.detail.processDefinitionKey === "epc_purchase_plan_master";
    },
    isPlanAdjust (state) {
      return state.detail.processDefinitionKey === "epc_purchase_plan_mod";
    },
    isPublicBidding (state) {
      return state.detail.processDefinitionKey === "epc_purchase_bid_public";
    },
    isInviteBidding (state) {
      return state.detail.processDefinitionKey === "epc_purchase_bid_invited";
    },
    isCompetitionArgue (state) {
      return state.detail.processDefinitionKey === "epc_purchase_bid_comp";
    },
    isPreBidding (state) {
      return state.detail.processDefinitionKey === "epc_purchase_pre_bid";
    },
    isNotPreBidding (state) {
      return state.detail.processDefinitionKey === "epc_purchase_no_pre_bid";
    },
    isInquiry (state) {
      return (
        state.detail.processDefinitionKey === "epc_purchase_main_contract_sign"
      );
    },
    isEmergancyPurchase (state) {
      return state.detail.processDefinitionKey === "epc_purchase_emergency";
    },
    isTransfer (state) {
      return state.detail.processDefinitionKey === "epc_purchase_transfer";
    },
    isReturnToFactory (state) {
      return state.detail.processDefinitionKey === "epc_purchase_return";
    },
    // 收支台账
    isBalanceAccount (state) {
      return state.detail.processDefinitionKey === "epc_balance_contract";
    },
    // 现场剩余物资处理
    isRemMatHandle (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_balance_remain_material_handling"
      );
    },
    // 竣工结算
    isComSettle (state) {
      return (
        state.detail.processDefinitionKey === "epc_balance_completion_settle"
      );
    },
    // 竣工结算审核
    isComSettleComment (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_balance_completion_settle_review"
      );
    },
    // 分包结算
    isSubSettle (state) {
      return state.detail.processDefinitionKey === "epc_balance_subpack_settle";
    },
    // 分包结算
    isSubSettleComment (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_balance_subpack_settle_review"
      );
    },
    // 成本执行报告
    isCosExeRep (state) {
      return (
        state.detail.processDefinitionKey === "epc_balance_cost_exec_report"
      );
    },
    // 成本执行报告
    isCosExeRepComment (state) {
      return (
        state.detail.processDefinitionKey ===
        "epc_balance_cost_exec_report_review"
      );
    },
    // 分包付款
    isSubpackPay (state) {
      return state.detail.processDefinitionKey === "epc_balance_subpack_pay";
    },
    // 分包支出申请
    isSubpackPayApp (state) {
      return (
        state.detail.processDefinitionKey === "epc_balance_subpack_expense_app"
      );
    },

    isBiddingRegister (state) {
      return state.detail.processDefinitionKey === "mark_bid_register";
    },

    isAddBidding (state) {
      return state.detail.processDefinitionKey === "mark_bid_create";
    },
    isBiddingResult (state) {
      return state.detail.processDefinitionKey === "mark_bid_jury";
    },
    isBiddingConfirm (state) {
      return state.detail.processDefinitionKey === "mark_bid_win";
    },



    // 备案立项申请
    isPutOnFileRequest (state) {
      return state.detail.processDefinitionKey === "scrm_iremote_filingConvertProjectProcess";
    },
    // 项目投标申请(EPC-非牵头人)
    isBidRequestEPCLeaderNot (state) {
      return state.detail.processDefinitionKey === "scrm_iremote_projectBidApplyEpcNotHeadProcess";
    },
    // 项目投标申请(EPC-牵头人)
    isBidRequestEPCLeader (state) {
      return state.detail.processDefinitionKey === "scrm_iremote_projectBidApplyEpcHeadProcess";
    },
    // 项目投标申请(设计)
    isBidRequestDesign (state) {
      return state.detail.processDefinitionKey === "scrm_iremote_projectBidApplyDesignProcess";
    },


    // 是否是调休，加班，请假，出差和公出审批
    isLeave (state) {
      const arr = [ 'att_leave', 'att_on_business', 'att_adjust' ]
      return arr.includes(state.detail.processDefinitionKey)
    },
    // 是否是补卡审批
    isReplace (state) {
      return state.detail.processDefinitionKey === "att_check_apply";
    },

    // 是否是技术质量-安全检查-策划表
    ofc_quality_plan (state) {
      return state.detail.processDefinitionKey === "ofc_quality_plan";
    },
    // 是否是技术质量-安全检查-工作表
    ofc_quality_work (state) {
      return state.detail.processDefinitionKey === "ofc_quality_work";
    },
    // 是否是技术质量-安全检查-整改工作单
    ofc_quality_rectify (state) {
      return state.detail.processDefinitionKey === "ofc_quality_rectify";
    },


    // 是否是整改人审批
    isChargeOfRectification (state) {
      return state.detail.taskDefinitionKey === "ofc_quality_rectifier";
    },


    // 是否是部门协作单
    safety_collaborate (state) {
      return state.detail.processDefinitionKey === "safety_collaborate";
    },


    // 是否是受检项目人员审批
    ofc_quality_project_member (state) {
      return (
        state.detail.taskDefinitionKey === "ofc_quality_project_member"
      );
    },

    //是否是餐卡借用审批
    dine_card_temp (state) {
      return state.detail.processDefinitionKey === "dine_card_temp";
    },

    ofc_quality_fee_budget (state) {
      return state.detail.processDefinitionKey === "ofc_quality_fee_budget";
    },

    ofc_quality_fee_use (state) {
      return state.detail.processDefinitionKey === "ofc_quality_fee_use";
    },

    ofc_quality_risk (state) {
      return state.detail.processDefinitionKey === "ofc_quality_risk";
    },
    ofc_quality_meeting_issue (state) {
      return state.detail.processDefinitionKey === "ofc_quality_meeting_issue";
    },
    ofc_quality_meeting_decision (state) {
      return state.detail.processDefinitionKey === "ofc_quality_meeting_decision";
    },
    oa_use_car (state) {
      return state.detail.processDefinitionKey === "oa_use_car";
    },
    oa_technical_information_grant (state) {
      return state.detail.processDefinitionKey === "oa_technical_information_grant";
    },
    user_intern (state) {
      return state.detail.processDefinitionKey === "user_intern";
    },
  },

  mutations: {
    setTaskId (state, payload) {
      state.taskId = payload;
    },
    setInstanceId (state, payload) {
      state.instanceId = payload;
    },
    setLoading (state, payload) {
      state.loading = payload;
    },
    setDetail (state, payload) {
      state.detail = payload;
    },
    setHistory (state, payload) {
      state.history = payload;
    },

    setLog (state, payload) {
      state.log = payload;
    },

    // 打分
    setScore (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          phaseProgressData: {
            ...state.detail.extra.phaseProgressData,
            score: payload,
          },
        },
      };
    },
    setFormula (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          phaseProgressData: {
            ...state.detail.extra.phaseProgressData,
            formula: payload,
          },
        },
      };
    },
    setFactor (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          phaseProgressData: {
            ...state.detail.extra.phaseProgressData,
            factor: payload,
          },
        },
      };
    },

    // 填写督办完成情况
    setExecuteRemarks (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          superviseTaskData: {
            ...state.detail.extra.superviseTaskData,
            executeRemarks: payload,
          },
        },
      };
    },

    // 上传督办完成情况附件
    setExecuteAttachments (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          superviseTaskData: {
            ...state.detail.extra.superviseTaskData,
            executeAttachments: [
              ...(Array.isArray(
                state.detail.extra.superviseTaskData.executeAttachments
              )
                ? state.detail.extra.superviseTaskData.executeAttachments
                : []),
              ...payload,
            ],
          },
        },
      };
    },

    // 设置是否有延期字段
    setIsDelayed (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          superviseTaskData: {
            ...state.detail.extra.superviseTaskData,
            isDelayed: payload,
          },
        },
      };
    },

    setOldDelayedDate (state, payload) {
      state.oldDelayedDate = payload;
    },

    setDelayedDate (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          superviseTaskData: {
            ...state.detail.extra.superviseTaskData,
            delayedDate: payload,
          },
        },
      };
    },

    setDelayRemarks (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          superviseTaskData: {
            ...state.detail.extra.superviseTaskData,
            delayRemarks: payload,
          },
        },
      };
    },

    // 财务开票
    // 填写销方
    addSale (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          invoiceInfos: state.detail.extra.invoiceInfos
            ? {
              ...state.detail.extra.invoiceInfos,
              salesUnitId: payload.id,
              salesUnitName: payload.name,
            }
            : {
              salesUnitId: payload.id,
              salesUnitName: payload.name,
            },
        },
      };
    },

    // 填写开票人
    addDrawer (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,

          invoiceInfos: state.detail.extra.invoiceInfos
            ? {
              ...state.detail.extra.invoiceInfos,
              drawer: payload,
            }
            : {
              drawer: payload,
            },
        },
      };
    },
    // 填写开票时间
    addDrawDate (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          invoiceInfos: state.detail.extra.invoiceInfos
            ? {
              ...state.detail.extra.invoiceInfos,
              drawDate: payload,
            }
            : {
              drawDate: payload,
            },
        },
      };
    },

    // 添加新的发票
    addInvoice (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,

          invoiceInfos: state.detail.extra.invoiceInfos
            ? {
              ...state.detail.extra.invoiceInfos,
              invoiceList: payload,
            }
            : {
              invoiceList: payload,
            },
        },
      };
    },

    // 填写送达确认信息
    setExpress (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          sendInfo: state.detail.extra.sendInfo
            ? {
              ...state.detail.extra.sendInfo,
              ...payload,
            }
            : {
              ...payload,
            },
        },
      };
    },

    // 加签
    setSignUser (state, payload) {
      state.assignees = payload;
    },
    setSignType (state, payload) {
      state.signType = payload;
    },
    setOnSet (state, payload) {
      state.onset = payload;
    },

    // 设置是否需要财务审批
    setIsFinancialApproval (state, payload) {
      state.detail = {
        ...state.detail,
        extra: {
          ...state.detail.extra,
          contractChangeAmountData: {
            ...state.detail.extra.contractChangeAmountData,
            isFinancialApproval: payload,
          },
        },
      };
    },

  },

  actions: {
    getDetail ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        fetchTaskDetail({
          taskId: state.taskId,
          instanceId: state.instanceId,
        })
          .then((res) => {
              if (res && Array.isArray(res.proTasks)) {
                commit("setHistory", res.proTasks);
              }
              if (
                res &&
                res.currentTask &&
                typeof res.currentTask.extra === "string"
              ) {
                let extra = JSON.parse(res.currentTask.extra);
                if(!extra.seqNum && res.currentTask.seqNum) {
                  extra.seqNum = res.currentTask.seqNum
                }
                commit("setDetail", {
                  ...res.currentTask,
                  extra: extra,
                });
  
                // 记录一下延期日期的值，保存下来，当做执行人再次延期时的凭证，如果两次值不一致，则说明她改了。
                if (state.detail.extra && state.detail.extra.superviseTaskData) {
                  commit(
                    "setOldDelayedDate",
                    state.detail.extra.superviseTaskData.delayedDate
                  );
                }
  
                resolve();
              } else {
                reject();
              }
          })
          .catch(reject)
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    getLog ({ state, commit }) {
      fetchLog({
        instanceId: state.instanceId,
      }).then((res) => {
        if (res) {
          commit("setLog", res);
        }
      });
    },

    finishTask ({ state }, payload) {
      console.log(payload);
      console.log(state);
      console.log(state.taskDefinitionKey);

      return new Promise((resolve, reject) => {
        done({
          taskId: state.taskId,
          taskDefinitionKey: state.taskDefinitionKey,
          ...payload,
        })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },

    storageTask ({ state }, payload) {
      return new Promise((resolve, reject) => {
        storage({
          taskId: state.taskId,
          ...payload,
        })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },

    // 加签查询
    getSign ({ state }) {
      return new Promise((resolve, reject) => {
        fetchSign({
          taskId: state.taskId,
          processDefId: state.detail.processDefId,
          processInstanceId: state.instanceId,
          taskDefkey: state.detail.taskDefinitionKey,
        })
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      });
    },
    // 加签暂存
    signTaskStorage ({ state }) {
      return new Promise((resolve, reject) => {
        signStorage({
          taskId: state.taskId,
          signType: state.signType,
          assignees: state.assignees.map((item) => item.userId),
          onSet: state.onset,
        })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },

    // 加签提交
    signTaskSubmit ({ state }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        signSubmit({
          taskId: state.taskId,
          signType: state.signType,
          assignees: state.assignees.map((item) => item.userId),
          onSet: state.onset,
          map: payload,
        })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },
  },
};
