// 审批详情
export default {

  namespaced: true,

  state() {
      return {
        rectificationMeasure: '', // 整改情况
        approvalList: [], // 附件
      }
  },

  mutations: {
    setRectificationMeasure(state, payload) {
        state.rectificationMeasure = payload;
    },
    setAttachment(state, payload) {
        state.approvalList = payload.map(item => {
          return {
            id: item.id,
            name: item.name,
            completePath: item.completePath,
            type: 'approvalDetail',
          }
        })
      console.log(state.approvalList)
    },
    delApprovalFile(state, payload){
      state.approvalList.splice(payload, 1);
    }
  }
}