<template>
  <span v-if="routeObj">
    <slot />
  </span>
  <span v-else></span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  // 权限按钮
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  computed: {
    fullPath() {
      return this.$route.path + "/" + this.path;
    },
    ...mapGetters("auth", ["getCurrentRoute"]),
    routeObj() {
      return this.getCurrentRoute(this.fullPath);
    },
  },
};
</script>