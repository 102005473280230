import { recoveryArr } from "@/utils/groupBy.js";

export default {

    namespaced: true,

    state() {
        return {
            invoiceObj: {
              design:[],
              equip:[],
              build:[],
              others:[]
            },
            invoiceList:null,//invoiceObj转换为List
            type:'',//开票类型：设计，设备，建安，其他
        }
    },

    getters:{
      invoiceByType(state) {
            return state.invoiceObj[state.type] || []
        },
      totalAmtObj(state){ // 开票总金额
        let obj = {}
        for(const type in state.invoiceObj){
          if(!Array.isArray(state.invoiceObj[type])){
            return obj[type] = 0
          }else{
            obj[type] = state.invoiceObj[type].reduce((pre,cur)=>{
              return (cur.amount || 0) + pre
            },0)
          }
        }
        return obj
      }
    },

    mutations: {
        setInvoice(state, payload) {
            state.invoiceObj = payload
        },
        setType(state, payload) {
          state.type = payload
        },

        addInvoice(state,payload){
          console.log(payload)
          console.log(state.invoiceObj,state.type)
          if(!Array.isArray(state.invoiceObj[state.type])){
            state.invoiceObj[state.type] = []
          }else{
            state.invoiceObj[state.type].push({
              ...payload,
              category: state.type
            })
          }
        },

        editInvoice(state, payload) {
          console.log(state,payload)
          let value = payload.val
            state.invoiceObj[state.type].splice(payload.index, 1, {
              ...value,
              category: state.type
            })
        },

        delInvoice(state, payload) {
           let list = state.invoiceObj[state.type]
           list.splice(payload, 1);
        },

        setInvoiceList(state){
          console.log(222)
          state.invoiceList =  recoveryArr(state.invoiceObj) || []
        }
    }
}