export default [{
    name: '工作台',
    path: '/workplace'
}, {
    name: '通讯录',
    path: '/address-list'
}, {
    name: '消息中心',
    path: '/message-center'
}, {
    name: '个人设置',
    path: '/personal-setting'
}]