import { fetchList, remove, update } from '@/api/draft'

export default {

    namespaced: true,

    state() {
        return {
            list: [],
        }
    },


    mutations: {

        setList(state, payload) {
            state.list = payload
        }
    },

    actions: {

        getDrafts({ commit },) {

            fetchList().then(res => {
                if (Array.isArray(res)) {
                    commit('setList', Object.freeze(res))
                }
            })

        },

        updateDraft({ dispatch }, payload) {
            update({
                ...payload
            }).then(() => {
                dispatch('getDrafts')
            })
        },

        deleteDraft({ commit, state }, payload) {
            remove({
                id: payload.id,
            }).then(() => {
                commit('setList', state.list.filter(item => item.id !== payload.id));
            })
        }


    }
}